$default-font-size: 12px;

$font-awesome-family: 'Font Awesome 6 Pro';
$font-awesome-family-before-icon: 'Font Awesome 6 Pro';
$font-awesome-solid-family: 'Font Awesome 6 Pro';
$font-awesome-brands-family: 'Font Awesome 6 Brands';
$font-awesome-duotone-family: 'Font Awesome 6 Duotone';

$boxing-vertical-default-space: 50px;
$boxing-horizontal-default-space: 30px;
$padding-lateral-default: 30px;
$side-menu-width: 240px;

$radius: 3px;
$transition-time: .5s;

/*#region Colors */
/*#region Common colors */
$color-bg-color: #f2f4f6;
$color-text-default: #707070;
$color-text-default-hover: darken($color-text-default, 35%);
$color-border-default: #ddd;
$color-icon-menu: #90a8b1;

$color-light-blue: #303F9F;
$color-dark-blue: darken($color-light-blue, 16%);
$color-blue-gradient: linear-gradient( 135deg, $color-light-blue 10%, #512DA8 135%);

$color-yellow: #ffe300;

$color-orange: #ffb300;
$color-dark-orange: darken($color-orange, 7%); 

$color-green: #22C15E;
$color-dark-green: #22C15E;

$color-red: #ff6027;
$color-dark-red: darken($color-red, 10%);

$color-availability-background: #F4F4F4;

$color-purple: #673AB7;

$color-light-gray: #f0f0f0;
$color-gray: #c3c3c3;
$color-dark-gray: darken($color-gray, 8%);

/*#region Ecommerce colors*/
$color-ecommerce-primary: #187bd1 !default;
$color-ecommerce-primary-dark: darken($color-ecommerce-primary, 13%) !default;
$color-ecommerce-secondary: #22C15E !default;
$color-ecommerce-bg-header: $color-ecommerce-primary-dark !default;

$color-ecommerce-royal-seat-price-1: rgba(0, 188, 212, .3);
$color-ecommerce-royal-seat-price-2: rgba(140, 36, 168, .3);
$color-ecommerce-royal-seat-price-3: rgba(118, 255, 3, .4);

/*#endregion */

/*#region Gradients */

@mixin bg-gradient-light-blue($hover: true) {
    background-image: linear-gradient(135deg, $color-light-blue 10%, $color-dark-blue 75%);

    @if $hover {
        &:hover {
            background-image: linear-gradient( 135deg, #6bbdff 10%, darken($color-light-blue, 14%) 80%);
        }
    }
}

@mixin bg-gradient-orange {
    background-image: linear-gradient( 135deg, $color-orange 60%, lighten($color-dark-orange, 7%) 90%);
}

@mixin bg-gradient-green($hover: true) {
    background-image: linear-gradient(135deg, $color-dark-green 10%, #22ab5f 140%);

    @if $hover {
        &:hover {
            background-image: linear-gradient(135deg, $color-dark-green 10%, darken(#22ab5f, 10%) 140%);
        }
    }
}

/*#endregion */

/*#endregion */

/*#region Media queries */

$break-mobile: 544px;
$break-tablet: 768px;
$break-phablet: 992px;
$break-desktop: 1200px;
$break-desktop-hd: 1400px;

@mixin media-mobile-small {
    @media (max-width: #{$break-mobile}) {
        @content;
    }
}

@mixin media-above-mobile-small {
    @media (min-width: #{$break-mobile + 1px}) {
        @content;
    }
}

@mixin media-mobile {
    @media (max-width: #{$break-tablet - 1px}) {
        @content;
    }
}

@mixin media-above-mobile {
    @media (min-width: #{$break-tablet}) {
        @content;
    }
}

@mixin media-only-tablet {
    @media (min-width: #{$break-tablet}) and (max-width: #{$break-phablet}) {
        @content;
    }
}

@mixin media-tablet {
    @media (max-width: #{$break-phablet - 1px}) {
        @content;
    }
}

@mixin media-above-tablet {
    @media (min-width: #{$break-phablet}) {
        @content;
    }
}

@mixin media-phablet {
    @media (max-width: #{$break-desktop - 1px}) {
        @content;
    }

    .above-phablet {
        display: none !important;
    }
}

@mixin media-desktop {
    @media (min-width: #{$break-desktop}) {
        @content;
    }
}

@mixin media-desktop-hd {
    @media (min-width: #{$break-desktop-hd}) {
        @content;
    }
}

@media (min-width: #{$break-phablet}) {
    .only-tablet {
        display: none !important;
    }
}

@media (min-width: #{$break-tablet}) {
    .only-mobile {
        display: none !important;
    }
}

@media (min-width: #{$break-phablet}) {
    .below-phablet {
        display: none !important;
    }
}

/*#endregion */

/*#region Fonts and texts */

@mixin text-overflow {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@mixin font-muli {
    font-family: 'Muli', sans-serif !important;
}

@mixin font-montserrat {
    font-family: 'Montserrat', sans-serif !important;
}

@mixin font-poppins {
    font-family: 'Poppins', sans-serif !important;
}

@mixin font-inter {
    font-family: 'Inter', sans-serif !important;
}

/*#endregion */

/*#region Animate */

@mixin animate-in-sequence ($for: 10, $interval: .09s) {
    @for $i from 1 through $for {
        &:nth-of-type(#{$i}) {
            animation-delay: $i * $interval;
        }
    }
}

@mixin animate ($property: all) {
    transition: $property .100s ease;
}

@mixin animate-50 ($property: all) {
    transition: $property .050s ease;
}

@mixin animate-100 ($property: all) {
    transition: $property .100s ease;
}

@mixin animate-150 ($property: all) {
    transition: $property .150s ease;
}

@mixin animate-200 ($property: all) {
    transition: $property .200s ease;
}

@mixin animate-300 ($property: all) {
    transition: $property .300s ease;
}

@mixin animate-400 ($property: all) {
    transition: $property .400s ease;
}

@mixin animate-500 ($property: all) {
    transition: $property .500s ease;
}

/*#endregion */

/*#region Animate to */

@mixin animate-to-top ($time: $transition-time) {
    -webkit-animation: slide-in-top $time cubic-bezier(.25,.46,.45,.94) both;
    animation: slide-in-top $time cubic-bezier(.25,.46,.45,.94) both;
}

@mixin animate-to-right ($time: $transition-time) {
    -webkit-animation: slide-in-right $time cubic-bezier(.25,.46,.45,.94) both;
    animation: slide-in-right $time cubic-bezier(.25,.46,.45,.94) both;
}

@mixin animate-to-bottom ($time: $transition-time) {
    -webkit-animation: slide-in-bottom $time cubic-bezier(.25,.46,.45,.94) both;
    animation: slide-in-bottom $time cubic-bezier(.25,.46,.45,.94) both;
}

@mixin animate-to-left ($time: $transition-time) {
    -webkit-animation: slide-in-left $time cubic-bezier(.25,.46,.45,.94) both;
    animation: slide-in-left $time cubic-bezier(.25,.46,.45,.94) both;
}

/*#endregion */

/*#region Disappear to */
@mixin disappear-to-top ($time: $transition-time) {
    -webkit-animation: disappear-to-top $time cubic-bezier(.25,.46,.45,.94) both;
    animation: disappear-to-top $time cubic-bezier(.25,.46,.45,.94) both;
}

@mixin disappear-to-right ($time: $transition-time) {
    -webkit-animation: disappear-to-right $time cubic-bezier(.25,.46,.45,.94) both;
    animation: disappear-to-right $time cubic-bezier(.25,.46,.45,.94) both;
}

@mixin disappear-to-bottom ($time: $transition-time) {
    -webkit-animation: disappear-to-bottom $time cubic-bezier(.25,.46,.45,.94) both;
    animation: disappear-to-bottom $time cubic-bezier(.25,.46,.45,.94) both;
}

@mixin disappear-to-left ($time: $transition-time) {
    -webkit-animation: disappear-to-left $time cubic-bezier(.25,.46,.45,.94) both;
    animation: disappear-to-left $time cubic-bezier(.25,.46,.45,.94) both;
}

/*#endregion */

/*#region Rounded */

@mixin rounded ($radius: $radius) {
    border-radius: $radius;
}

@mixin rounded-top ($radius: $radius) {
    border-top-left-radius: $radius;
    border-top-right-radius: $radius;
}

@mixin rounded-right ($radius: $radius) {
    border-top-right-radius: $radius;
    border-bottom-right-radius: $radius;
}

@mixin rounded-bottom ($radius: $radius) {
    border-bottom-left-radius: $radius;
    border-bottom-right-radius: $radius;
}

@mixin rounded-left ($radius: $radius) {
    border-top-left-radius: $radius;
    border-bottom-left-radius: $radius;
}

@mixin rounded-top-right ($radius: $radius) {
    border-top-right-radius: $radius;
}

@mixin rounded-top-left ($radius: $radius) {
    border-top-left-radius: $radius;
}

@mixin rounded-bottom-right ($radius: $radius) {
    border-bottom-right-radius: $radius;
}

@mixin rounded-bottom-left ($radius: $radius) {
    border-bottom-left-radius: $radius;
}

/*#endregion */

/*#region Shadow */

@mixin shadow {
    box-shadow: 0 1px 3px rgba(0,0,0,0.1), 0 1px 2px rgba(0,0,0,0.2);
}

@mixin shadow-bottom {
    box-shadow: 0 2px 3px rgba(0,0,0,0.15), 0 2px 2px rgba(0,0,0,0.2);
}

@mixin shadow-higher {
    box-shadow: 0 5px 22px rgba(0,0,0,.15), 0 0 38px rgba(0,0,0,.15);
}

/*#endregion */

/*#region Border */

@mixin border-default {
    border: 1px solid $color-border-default;
}

@mixin border-top {
    border-top: 1px solid $color-border-default;
}

@mixin border-right {
    border-right: 1px solid $color-border-default;
}

@mixin border-bottom {
    border-bottom: 1px solid $color-border-default;
}

@mixin border-left {
    border-left: 1px solid $color-border-default;
}

/*#endregion */

@mixin ribbon ($color: $color-orange) {
    position: absolute;
    padding: 1px 5px;
    font-size: 11px;
    font-weight: 700;
    background-color: $color;
    color: #fff;

    &:before {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border: 2px solid transparent;
    }
}

@mixin small-text {
    font-weight: 700;
    font-size: 13px;
}

@mixin card {
    @include rounded;
    padding: 15px;
    background-color: #fff;
}

@mixin badge {
    @include font-poppins;
    @include rounded;
    min-width: 13px;
    padding: 2px 6px;
    white-space: nowrap;
    font-weight: 600;
    text-align: center;
}

/* Search Count Results - Temp Hide */
#ngb-live {
    display: none;
}