﻿.swal2-html-container {
    text-align: center;
}

.swal2-container.swal2-center.swal2-backdrop-show,
.swal2-container .swal2-center .swal2-fade .swal2-shown {
    background-color: rgba(0,0,0,0.5);
}

.swal2-container {
    .swal2-popup {
        @include shadow-higher;

        .swal2-icon.swal2-error {
            .swal2-x-mark-line-left,
            .swal2-x-mark-line-right {
                top: 1.9125em;
                left: 0.8625em;
                width: 2.3375em;
            }
        }

        .swal2-icon.swal2-success {
            .swal2-success-line-tip {
                top: 2.175em;
                left: 0.775em;
                width: 1.1625em;
            }

            .swal2-success-line-long {
                top: 1.875em;
                right: 0.7em;
                width: 1.9375em;
            }
        }

        .swal2-icon {
            width: 4em;
            height: 4em;
            line-height: 4em;
            margin-bottom: 1.875em;
        }

        .swal2-icon-text {
            font-size: 2.75em;
        }

        h2.swal2-title {
            margin-top: 0.3em;
            font-size: 1.3rem;
            font-weight: 700;
            color: #777 !important;
        }

        #swal2-content {
            line-height: 24px;
            font-weight: 600;
            font-size: 14px;
            color: #aaa;

            option {
                padding: 5px 0;
            }

            .sweet-alert-table {
                @include rounded;
                overflow: auto;
                max-height: 250px;
                margin: 30px 0 5px;
                padding: 5px;
                border: 1px solid #ccc;

                table {
                    width: 100%;
                }

                thead th {
                    background-color: #eee;
                }

                tbody tr td {
                    border-top: 1px solid #eee;
                }
            }
        }

        .swal2-actions {
            margin-top: 2.25em;

            .swal2-styled {
                @include animate-200;
                box-shadow: none !important;
            }

            .swal2-cancel {
                font-size: 13px;

                &:hover {
                    background-color: #888;
                }
            }

            .swal2-confirm {
                font-size: 16px;
            }
        }

        &.alert-positive .swal2-actions .swal2-confirm {
            background-color: $color-light-blue;

            &:hover {
                background-color: $color-dark-blue;
            }
        }

        &.alert-negative .swal2-actions .swal2-confirm {
            background-color: $color-red;

            &:hover {
                background-color: $color-dark-red;
            }
        }
    }
}

.swal2-icon-content {
    font-size: 40px;
}
